import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "patching-reports"
    }}>{`Patching reports`}</h1>
    <p>{`For each patching event there is a possibility to see a report of what packages were installed, updated or removed on each machine during execution of the event. This is an asynchronous process, which requires the user to first initiate the generation, and later retrieve it's result.`}</p>
    <p>{`AutoPatcher user interface enable to get following reports:`}</p>
    <ul>
      <li parentName="ul">{`Bundled report from selected events which is send to the email and is valid for 30 minutes.`}</li>
      <li parentName="ul">{`CSV report from selected events`}</li>
    </ul>
    <p><img alt="event-list" src={require("../assets/reports/event-list.png")} /></p>
    <ul>
      <li parentName="ul">{`Report from single event`}</li>
      <li parentName="ul">{`Report from single machine which was patched in the selected event`}</li>
    </ul>
    <p><img alt="single-event" src={require("../assets/reports/single-event.png")} /></p>
    <p><strong parentName="p">{`start time`}</strong>{` and `}<strong parentName="p">{`end time`}</strong>{` values in the report correspond to the `}<em parentName="p">{`actual`}</em>{` patching beginning and end, e.g. the patching can end before the time window had elapsed in which case `}<strong parentName="p"><inlineCode parentName="strong">{`end_time`}</inlineCode></strong>{` will be earlier than `}<strong parentName="p"><inlineCode parentName="strong">{`window_end_time`}</inlineCode></strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      